import { useEffect } from "react";
import Logo from "../images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";

export default function Splash2() {
  let Navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("student_url")) {
      Navigate("/SchoolCode");
    } else {
      Navigate("/Login");
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row splash-2">
        <div className="col-12 m-auto px-5">
          <img src={Logo} className="" style={{ width: "100%" }} />
          <div className="row mt-4">
            <div className="col-12 m-auto">
              <NavLink to="/Login" className="text-decoration-none">
                <div className="log-btn p-3 text-white">Login</div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
