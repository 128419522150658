export const get_upcoming_class_success = "get/upcoming/classess/success";
export const get_history_class_success = "get/history/classess/success";
export const get_selected_class_success = "get/seleted/classess/success";

export const get_upcoming_class_error = "get/upcoming/classess/error";
export const get_history_class_error = "get/history/classess/error";
export const get_selected_class_error = "get/seleted/classess/error";

export const get_upcoming_class_loading = "get/upcoming/classess/loading";
export const get_history_class_loading = "get/history/classess/loading";
export const get_selected_class_loading = "get/seleted/classess/loading";


