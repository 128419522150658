import { useState, useEffect } from "react";
import Lottie from "lottie-react";
import Bicycle from "../images/cycle.json";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../env";

export default function Cycle() {
  const Navigate = useNavigate();

  const { id } = useParams();
  // console.log("cycle", id);

  const [duration, setDuration] = useState(10);

  useEffect(() => {
    if (duration <= 0) {
      Navigate(`/Question/${id}`);
      return;
    }

    const timer = setInterval(() => {
      setDuration((prevDuration) => prevDuration - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [duration]);

  const formatDuration = () => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // setTimeout(() => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

  //   var raw = JSON.stringify({
  //     "quiz_id": id
  //   });

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: 'follow'
  //   };

  //   fetch(`${API_URL}quiz/join/start/upcoming/quiz`, requestOptions)
  //     .then(response => response.json())
  //     .then(result => { console.log(result) })
  //     .catch(error => console.log('error', error));
  // }, duration * 1000)

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-8 m-auto">
            <p
              className="px-4 pt-5 mt-5 agre-txt-fold"
              style={{
                color: "#DC1111",
                fontSize: 16,
                fontWeight: 500,
                fontFamily: "inter",
              }}
            >
              Do not Close or Refresh this window
            </p>
            <Lottie
              className="mt-5"
              animationData={Bicycle}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-10 m-auto">
          <div
            className="mt-5 mb-5"
            style={{
              width: "100%",
              height: 50,
              backgroundColor: "#ED7A2B",
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color: "#fff",
              fontSize: 20,
              fontWeight: 500,
              fontFamily: "inter",
            }}
          >
            Exam Starts in{" "}
            <span
              className="ps-2"
              style={{
                color: "#FAFF10",
                fontSize: 18,
                fontWeight: 500,
                fontFamily: "inter",
              }}
            >
              {formatDuration()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
