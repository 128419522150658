import { instance } from "../../instance";
import {
  get_history_class_error,
  get_history_class_loading,
  get_history_class_success,
  get_selected_class_error,
  get_selected_class_loading,
  get_selected_class_success,
  get_upcoming_class_error,
  get_upcoming_class_loading,
  get_upcoming_class_success,
} from "./class.types";

export const getUpcomingClassApi = () => async (dispatch) => {
  dispatch({ type: get_upcoming_class_loading });
  try {
    let res = await instance.post("student/class/get/upcoming/classes");
    if (res.data.status == 1) {
      console.log(res.data, "in upcoming api fun");
      dispatch({ type: get_upcoming_class_success, payload: res.data.clases });
    }
  } catch (err) {
    dispatch({ type: get_upcoming_class_error });
  }
};
export const getHistoryClassApi = () => async (dispatch) => {
  dispatch({ type: get_history_class_loading });
  try {
    let res = await instance.post("student/class/get/completed/classes");

    if (res.data.status == 1) {
      dispatch({ type: get_history_class_success, payload: res.data.clases });
    }
  } catch (err) {
    dispatch({ type: get_history_class_error });
  }
};

export const getslectedClassApi = (id) => async (dispatch) => {
  dispatch({ type: get_selected_class_loading });
  try {
    let res = await instance.post("student/class/getclass/byid", {
      class_id: id,
    });
    if (res.data.status == 1) {
      console.log(res.data, "data in seleted class function ");
      dispatch({ type: get_selected_class_success, payload: res.data.sen });
    }
  } catch (err) {
    dispatch({ type: get_selected_class_error });
  }
};

export const getClassHistory = (status) => async (dispatch) => {
  dispatch({ type: get_history_class_loading });
  try {
    let res = await instance.post("student/profile/get/class/bystatus", {
      status: status,
    });
    if (res.data.status == 1) {
      console.log(res.data, 'data of class history');
      dispatch({ type: get_history_class_success, payload: res.data.class_list });
    }

  } catch (err) {
    dispatch({ type: get_history_class_error });
  }
};


