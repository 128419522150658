import { instance } from "../../instance";
import {
    get_upcoming_quiz_error,
    get_upcoming_quiz_loading,
    get_upcoming_quiz_success
} from "./Quiz.types"

export const getUpcomingQuizApi = () => async (dispatach) => {
    dispatach({ typeof: get_upcoming_quiz_loading });
    try {
        let res = await instance.post("student/quiz/get/upcoming/quiz");
        if (res.data.status == 1) {
            console.log(res.data, "in upcoming api fun of quiz");
            dispatach({ type: get_upcoming_quiz_success, payload: res.data.quiz });
        }
    } catch (err) {
        dispatach({ type: get_upcoming_quiz_error });
    }
};

export const getQuizHistory = (status) => async (dispatch) => {
    dispatch({ type: get_upcoming_quiz_loading });
    try {
      let res = await instance.post("student/profile/get/quiz/bystatus", {
        status: status,
      });
      if (res.data.status == 1) {
        console.log(res.data, 'data of quiz history');
        dispatch({ type: get_upcoming_quiz_success, payload: res.data.quiz_list });
      }
  
    } catch (err) {
      dispatch({ type: get_upcoming_quiz_error });
    }
  };

