import { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { devalayaInstance } from "../devalayaInstance";

const FreeCourses = () => {
  const [free_courses, setFreeCourses] = useState([]);
  const navigate = useNavigate();

  //function for get all free courses
  const getAllFreecourses = async () => {
    try {
      let res = await devalayaInstance("getcategory");
      if (res.data.status) {
        setFreeCourses(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllFreecourses();
  }, []);
  return (
    <>
      <Header />
      <div
        style={{
          margin: "80px 10px",
        }}
      >
        {free_courses.map((el) => (
          <div key={el._id} className="row">
            <div className="col-12 m-auto mt-2">
              <div
                className="card bg-white rounded-4 border-0"
                style={{ padding: "8px" }}
              >
               
                <img
                  src={`https://api.devalayaelearn.com/images/${el.image}`}
                  className="card-img-top"
                  alt="..."
                  style={{ borderRadius: 10 }}
                />
                <div className="card-body px-0">
                  <div
                    className="text-start assi-declr-txt"
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      color: "#1E1E1E",
                      fontFamily: "inter",
                    }}
                  >
                    {el.name}
                  </div>
               
                  <div
                    onClick={() => navigate(`/freecourses/${el._id}`)}
                    className="p-2 mt-2 assi-declr-txt"
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                      border: "1px solid #ED7A2B",
                      borderRadius: 5,
                      color: "#ED7A2B",
                      fontSize: 18,
                      fontWeight: 600,
                      fontFamily: "inter",
                    }}
                  >
                    Go To Course
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default FreeCourses;
