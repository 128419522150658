import { instance } from "../../instance";
import { get_exam_data_error, get_exam_data_loading, get_exam_data_success } from "./exam.types"

export const getExamByStatus = (status) => async(dispatch)=>{
    dispatch({type:get_exam_data_loading});
    try{
        let res = await instance.post("student/profile/get/exam/bystatus",{
            status:status,
        });
        if(res.data.status==1){
            dispatch({type:get_exam_data_success,
            payload:res.data.exam_list});
        }
    }
    catch(err){
        dispatch({type:get_exam_data_error});
    }
}