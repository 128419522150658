import ReactPlayer from "react-player/lazy";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { devalayaInstance } from "./../devalayaInstance";
import { useEffect, useState } from "react";
import { MdChevronLeft } from "react-icons/md";

const PlayVideo = () => {
  const { video_id, lesson_id } = useParams();
  const [details, setDetails] = useState({});
  const navigate = useNavigate();

  console.log({ video_id, lesson_id });

  const getLessonDeteils = async () => {
    try {
      let res = await devalayaInstance.post("getlessonvideo", {
        lesson_id: lesson_id,
        type: "video",
      });
      if (res.data.status) {
        let req_data = res.data.data.filter((el) => el.video_id === video_id);
        setDetails(req_data[0]);
        // console.log(req_data, "detials");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLessonDeteils();
  }, []);

  return (
    <>
      <Header />
      <div
        style={{
          marginTop: "80px",
        }}
      >
        <div className="row px-2">
          <div className="col-12">
            <div className="card p-3 border-0 rounded-3 bg-white">
              <div className="row d-flex justify-content-between align-items-center">
                <div
                  className="col-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <MdChevronLeft
                    className="text-end"
                    style={{ fontSize: 30 }}
                  />
                </div>
                <div className="col-10">
                  <h5
                    className="text-start homelive-txt"
                    style={{
                      fontSize: 18,
                      fontFamily: "inter",
                      fontWeight: 600,
                    }}
                  >
                    {details.title}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {console.log(video_id, "betwwn the comp")}
      {video_id && (
        <ReactPlayer
          width="100%"
          controls={true}
          url={`https://www.youtube.com/watch?v=${video_id}`}
          config={{
            youtube: {
              playerVars: { apiKey: "AIzaSyDbAUaPxZWyDIuPfWr_0K6hsEs69plvNDU" },
            },
          }}
        />
      )}

      <Footer />
    </>
  );
};

export default PlayVideo;
