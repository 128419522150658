import { useEffect, useState } from "react";
import { ImArrowLeft2 } from "react-icons/im";
import { MdOutlineAddBox } from "react-icons/md";
import { Send } from "./SvgIcon";
import MyImg from "../images/myimage.jpg";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";

export default function Chat() {
  const [msg, setMsg] = useState();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const socket = io("http://localhost:4000");

  const sendMessage = () => {
    // Send the message to the server
    socket.emit("message", messageInput);

    // Clear the input field
    setMessageInput("");
  };

  useEffect(() => {
    // Listen for incoming messages from the server
    socket.on("message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });
    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div
          className="p-0"
          style={{ width: "100%", marginTop: "110px", marginBottom: "90px" }}
        >
          <div className="row fixed-top">
            <div className="col-md-12">
              <div className="card py-2 rounded-0 border-0">
                <div className="row py-3">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => navigate(-1)}
                      className="btn border-0"
                      style={{
                        height: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ImArrowLeft2 style={{ fontSize: 20 }} />
                    </button>
                    <div
                      className="ms-2"
                      style={{ borderRadius: 50, width: 50, height: 50 }}
                    >
                      <img
                        src={MyImg}
                        className=""
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 50,
                        }}
                      />
                    </div>
                    <span
                      className="assi-nav-txt"
                      style={{ fontWeight: 500, fontSize: 22, paddingLeft: 15 }}
                    >
                      Smriti Arora
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="message-wrap">
            <div className="message-list me mt-2">
              <div className="msg">
                <div className="p-1 sub-name-txt">Hii.. ma'am</div>
              </div>
              <div className="time sub-name-txt">Today | 12:30</div>
            </div>
            <div className="message-list">
              <div className="msg m-0">
                <div className="p-1 sub-name-txt">Yes Beta please tell</div>
              </div>
              <div className="time sub-name-txt">Today | 12:31</div>
            </div>

            <div className="message-list me mt-3">
              <div className="msg">
                <div className="p-1 sub-name-txt">
                  Actually I was having doubts, regarding the last chpter
                </div>
              </div>
              <div className="time sub-name-txt">Today | 12:30</div>
            </div>
          </div>
        </div>
        <div
          className="row fixed-bottom px-4 py-4"
          style={{ backgroundColor: "#F0F5FE", alignItems: "center" }}
        >
          <div
            className="input-group p-3 mb-3"
            style={{ backgroundColor: "#fff", borderRadius: 40 }}
          >
            <span
              className="input-group-text border-0 bg-transparent"
              id="basic-addon1"
            >
              <MdOutlineAddBox
                className=""
                style={{ fontSize: 30, color: "#545454" }}
              />
            </span>
            <input
              type="text"
              value={msg}
              onChange={(e) => {
                setMsg(e.target.value);
              }}
              className="form-control border-0 py-2 shadow-none bg-transparent"
              placeholder="Type your Doubt Here..."
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
            <span
              onClick={sendMessage}
              className="input-group-text border-0 bg-transparent"
              id="basic-addon1"
            >
              <Send style={{}} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
