import { useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { devalayaInstance } from "../devalayaInstance";
import { useEffect, useState } from "react";
import { MdChevronRight } from "react-icons/md";

const ParticularLesson = () => {
  const { lesson_id } = useParams();
  const navigate = useNavigate();
  const [lesson, setlesson] = useState([]);

  const getLessonsVideos = async (id) => {
    try {
      let res = await devalayaInstance.post(`getlessonvideo`, {
        lesson_id: id,
        type: "video",
      });
      if (res.data.status) {
        setlesson(res.data.data);
        console.log(res.data.data, "particular lesson data");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLessonsVideos(lesson_id);
  }, []);

  return (
    <>
      <Header />

      <div
        style={{
          marginTop: "80px",
        }}
      >
        {lesson?.map((el) => (
          <div
            key={el._id}
            onClick={() =>
              navigate(`/playvideo/${el.video_id}/${el.lesson_id}`)
            }
            className="row px-2 my-2"
          >
            <div className="col-12">
              <div className="card p-3 border-0 rounded-3 bg-white">
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-10">
                    <h5
                      className="text-start homelive-txt"
                      style={{
                        fontSize: 18,
                        fontFamily: "inter",
                        fontWeight: 600,
                      }}
                    >
                      {el.title}
                    </h5>
                  </div>
                  <div className="col-2">
                    <MdChevronRight
                      className="text-end"
                      style={{ fontSize: 30 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </>
  );
};

export default ParticularLesson;
