import {
  AUTH_SIGN_IN_ERROR,
  AUTH_SIGN_IN_LOADING,
  AUTH_SIGN_IN_SUCCESS,
  AUTH_SIGN_OUT,
} from "./auth.types";

const token = localStorage.getItem("school_token") || "";
const userName = localStorage.getItem("userName") || "";
export const authInitalState = {
  loading: false,
  data: {
    token: token,
    isAuthenticated: token && userName ? true : false,
    userName: userName,
  },
  error: false,
};

export const authReducer = (state = authInitalState, { type, payload }) => {
  switch (type) {
    case AUTH_SIGN_IN_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case AUTH_SIGN_IN_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case AUTH_SIGN_IN_SUCCESS: {
      localStorage.setItem("school_token", payload.token);
      localStorage.setItem("userName", payload.userName);
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return {
        ...state,
        loading: false,
        
        data: {
          userName: payload.userName,
          token: payload.token,
          isAuthenticated: true,
        },
        error: false,
      };
    }
    case AUTH_SIGN_OUT: {
      localStorage.removeItem("userName");
      localStorage.removeItem("school_token");
      return {
        ...state,
        data: {
          token: "",
          isAuthenticated: false,
        },
      };
    }
    default:
      return state;
  }
};
