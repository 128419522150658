import { useEffect, useState } from "react";
import Cardimg from "../images/cardimg.png";
import { NavLink } from "react-router-dom";
import { API_URL } from "../env";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "./Loader";

export default function EnrollLiveClass() {
  const [PreRecord, setPreRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getEnrollLive = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("school_token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    setIsLoading(true)
    fetch(`${API_URL}/student/courses/get/live/course`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)
        console.log(result);
        if (result.status == 1) {
          setPreRecord([result.data]);
          localStorage.setItem("CourseName", result.data.name);
       
        }
        else if (result.status === "CUSTOM_ERR" || result.status ==="FILE_ERR") {
          toast(result.Backend_Error);
          setIsLoading(false)
        }
        else {
          toast("Something went wrong");
          console.log(result);
          setIsLoading(false)
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getEnrollLive();
    if (!localStorage.getItem("school_token")) {
    } else {
    }
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
        {
            isLoading ? 
              <Loader show={isLoading} />
          :
          <div className="col-12 px-3">
      
            {PreRecord?.map((item, index) => {
              return (
                <div key={index} className="row">
                  <div className="col-12 m-auto mt-2">
                    <div
                      className="card bg-white rounded-4 border-0"
                      style={{ padding: "8px" }}
                    >
                      <img
                        src={item.banner}
                        className="card-img-top"
                        alt="..."
                        style={{ borderRadius: 10 }}
                      />

                      {/* <iframe
    src={item.banner} // Use the PDF URL here
    title="PDF Viewer"
    width="100%"
    height="500px" // Adjust the height as needed
    type="application/pdf" // Specify the content type
  >
    Your browser does not support embedded PDFs.
    <a href={item.banner}>Download PDF</a> instead.
  </iframe> */}
                      <div className="card-body px-0">
                        <div
                          className="text-start assi-declr-txt"
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            color: "#1E1E1E",
                            fontFamily: "inter",
                            textTransform:'capitalize'
                          }}
                        >
                          {item.name}
                        </div>

                        <NavLink
                          to="/CourseHistory"
                          className="text-decoration-none"
                        >
                          <div
                            className="p-2 mt-2 assi-declr-txt"
                            style={{
                              width: "100%",
                              backgroundColor: "#fff",
                              border: "1px solid #ED7A2B",
                              borderRadius: 5,
                              color: "#ED7A2B",
                              fontSize: 18,
                              fontWeight: 600,
                              fontFamily: "inter",
                            }}
                          >
                            Go To Course
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })} 
       
         
          </div>
        }
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}
