import { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { MdChevronRight } from "react-icons/md";

const ParticularProductFromFreeCourses = () => {
  const [free_courses, setFreeCourses] = useState([]);
  const [lessons, setLessons] = useState([]);
  const navigate = useNavigate();
  const { product_id } = useParams();

  //function for get particular products
  const getparticularProduct = async (id) => {
    // return console.log(id, "in particular course");
    try {
      let res = await axios.post(
        `https://api.devalayaelearn.com/api/getproduct`,
        {
          id: id,
        },
        {
          headers: {
            "x-access-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjNmODU0NjM3YmE3NWVjZDIyZjA3YTZlIiwiaWF0IjoxNjkzODMzMjc5LCJleHAiOjE2OTY0MjUyNzl9.7mh92_pdCJfO6EyjDG0oJsdmYaeLxJK-UVY6shT1ymo",
            "Content-Type": "application/json",
          },
        }
      );
      setFreeCourses(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  //function for get all the lession of the product
  const getAllLessons = async (id) => {
    try {
      let res = await axios.post(
        `https://api.devalayaelearn.com/api/getlesson`,
        {
          product_id: id,
          type: "video",
        },
        {
          headers: {
            "x-access-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjNmODU0NjM3YmE3NWVjZDIyZjA3YTZlIiwiaWF0IjoxNjkzODMzMjc5LCJleHAiOjE2OTY0MjUyNzl9.7mh92_pdCJfO6EyjDG0oJsdmYaeLxJK-UVY6shT1ymo",
            "Content-Type": "application/json",
          },
        }
      );
      setLessons(res.data.data);
      console.log(res.data, "all lessons");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getparticularProduct(product_id);
    getAllLessons(product_id);
  }, []);

  return (
    <>
      <Header />
      <div
        style={{
          margin: "80px 10px 10px 10px",
        }}
      >
        {free_courses.map((el) => (
          <div key={el._id} className="row">
            <div className="col-12 m-auto mt-2">
              <div
                className="card bg-white rounded-4 border-0"
                style={{ padding: "8px" }}
              >
                <img
                  src={`https://api.devalayaelearn.com/images/${el.image}`}
                  className="card-img-top"
                  alt="..."
                  style={{ borderRadius: 10 }}
                />
                <div className="card-body px-0">
                  <div
                    className="text-start assi-declr-txt"
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      color: "#1E1E1E",
                      fontFamily: "inter",
                    }}
                  >
                    {el.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div>
        <div className="row px-2 my-2">
          <div
            className="text-start"
            style={{
              fontSize: 16,
              color: "#1E1E1E",
              fontWeight: 400,
              fontFamily: "inter",
            }}
          >
            Lessons
          </div>
        </div>

        {lessons?.map((el) => (
          <div
            key={el._id}
            onClick={() =>
              el?.topic[0]?.lesson_id === undefined
                ? navigate("/coming_soon")
                : navigate(`/lesson/${el.topic[0].lesson_id}`)
            }
            className="row px-2 my-2"
          >
            <div className="col-12">
              <div className="card p-3 border-0 rounded-3 bg-white">
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-10">
                    <h5
                      className="text-start homelive-txt"
                      style={{
                        fontSize: 18,
                        fontFamily: "inter",
                        fontWeight: 600,
                      }}
                    >
                      {el.name}
                    </h5>
                  </div>
                  <div className="col-2">
                    <MdChevronRight
                      className="text-end"
                      style={{ fontSize: 30 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </>
  );
};

export default ParticularProductFromFreeCourses;
