import { combineReducers, legacy_createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { authReducer } from "./Auth/auth.reducer";
import { homeReducer } from "./Home/home.reducers";
import { classReducer } from "./Classes/class.reducer";
import { quizReducer } from "./Quizzes/Quiz.reducer";
import { examReducer } from "./Exam/Exam.reducer";
import { assignmentReducer } from "./Assignments/assignment.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  class: classReducer,
  quiz: quizReducer,
  exam:examReducer,
  assignment: assignmentReducer,
});
export const store = legacy_createStore(rootReducer, applyMiddleware(thunk));
