//export const API_URL = "https://lmsapitipsg.intellix360.in";
// export const API_URL = "https://demo.intellix360.in/student/";
export const API_URL = localStorage.getItem("student_url");
export const theme = {
  "--prm_bg_login": "#f0f5fe",
  "--prm_bg_col": "#ed7a2b",
  "--white": "#faf0e6",
  "--prm_txt_color": "#545454",
  "--prm_txt_color2": "#5895cd",
};
