export const get_upcoming_quiz_success = "get/upcoming/classess/success";
export const get_history_quiz_success = "get/history/quizzes/success";
export const get_selected_quiz_success = "get/seleted/quizzes/success";

export const get_upcoming_quiz_error = "get/upcoming/quizzes/error";
export const get_history_quiz_error = "get/history/quizzes/error";
export const get_selected_quiz_error = "get/seleted/quizzes/error";

export const get_upcoming_quiz_loading = "get/upcoming/quizzes/loading";
export const get_history_quiz_loading = "get/history/quizzes/loading";
export const get_selected_quiz_loading = "get/seleted/quizzes/loading";