import { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { MdOutlineTranslate } from "react-icons/md";
import { TbArrowNarrowLeft } from "react-icons/tb";
import { TbArrowNarrowRight } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../env";
import { ToastContainer, toast } from "react-toastify";

export default function Question() {
  const [question, setQuestion] = useState([]);
  const [click, setClick] = useState(0);
  const [know, setknow] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [Index, setIndex] = useState();
  const [ansArr, setAnsArr] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [timeTaken, setTimeTaken] = useState(0);
  const [timeLimit, setTimeLimit] = useState(0);
  const [ID, setID] = useState('');

  // console.log(timeRemaining, 'timeTaken')

  const startQuiz = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    setStartTime(currentTime);
  };

  //function for get all the questions
  const Questions = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("school_token")}`
    );

    var raw = JSON.stringify({
      quiz_id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/student/quiz/join/start/upcoming/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          // console.log(result, "results for all the questions");
          setQuestion(result.questions);
          // console.log(result.questions[0]?.option1);
          setknow(1);
          const numQuestions = result.questions.length;
          setAnsArr(Array(numQuestions).fill(-1));
          setSelectedOptions(Array(result.questions.length).fill(-1));
          setID(result.quiz_id);
          const apiTimeLimit = result.timeperiod; // Replace 'timeLimit' with the actual API field name
          setTimeLimit(apiTimeLimit);
          startQuiz();
        } else {
          console.log(result, "error status 0");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //function for submit final submit
  const quizSubmit = async () => {
    if (quizCompleted) return; // Prevent multiple submissions

    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
    const timeTaken = currentTime - startTime;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("school_token")}`);

    var raw = JSON.stringify({
      quiz_id: id,
      ans_arr: selectedOptions,
      submit_timeperiod: timeTaken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${API_URL}/student/quiz/submit/quiz`, requestOptions);
      const result = await response.json();

      if (result.status === 1) {
        navigate(`/ReviewAnswer/${ID}`);
        setknow(1);
        setSelectedOptions(Array(result.questions.length).fill(-1));
      } else {
        console.log(result, "error status 0");
      }
    } catch (error) {
      console.log("error", error);
    }

    setQuizCompleted(true);

    // Store the time taken in the state
    setTimeTaken(timeTaken);
  };


  // const SaveNext = () => {
  //   // Update the selected option for the current question
  //   setSelectedOptions((prevOptions) => {
  //     const updatedOptions = [...prevOptions];
  //     updatedOptions[click] = Index;
  //     return updatedOptions;
  //   });

  //   if (click < question.length) {
  //     // Move to the next question
  //     setClick((prevClick) => prevClick + 1);
  //   } else {
  //     // If it's the last question, submit the quiz
  //     quizSubmit();
  //   }
  // };

  const SaveNext = () => {
    // Update the selected option for the current question
    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[click] = Index;
      return updatedOptions;
    });

    // Move to the next question
    setClick((prevClick) => prevClick + 1);

    // Use setTimeout to check if it's the last question after state updates
    setTimeout(() => {
      if (click === question.length - 1) {
        console.log("Submitting quiz...");
        quizSubmit();
      }
    }, 0);
  };


  const handleOptionClick = (optionIndex) => {
    setIndex(optionIndex);
    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[click] = optionIndex;
      return updatedOptions;
    });
  };

  const HandelSkip = () => {
    if (click === question.length - 1) return false;

    // Clear the selected option for the current question
    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[click] = -1;
      return updatedOptions;
    });

    // Move to the next question
    setClick((prevClick) => prevClick + 1);
  };

  const Next = () => {
    if (click < question.length - 1) {
      setClick((prevClick) => prevClick + 1);
    }
  };

  const quitQuit = () => {
    navigate('/Home');
    // toast('Thank you for your participation');
  }

  useEffect(() => {
    Questions();
    if (!localStorage.getItem("school_token")) {
    } else {
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (startTime > 0 && click < question.length) {
        const currentTime = Math.floor(Date.now() / 1000);
        const elapsedSeconds = currentTime - startTime;
        const remainingTime = Math.max(0, timeLimit - elapsedSeconds);
        setTimeRemaining(remainingTime);

        if (remainingTime === 0) {
          // Time's up, move to the next question
          if (!quizCompleted) {
            quizSubmit();
          }
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime, click, question.length, timeLimit, quizCompleted]);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsStr = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${minutesStr}:${secondsStr}`;
  }

  return (
    <div>
      <ToastContainer autoClose={5000} />
      <div
        className="container-fluid g-0"
        style={{ width: "100%", height: "100%" }}
      >
        <div className="row fixed-top">
          <div className="row g-0 px-2 py-4 d-flex bg-dark text-white justify-content-between align-items-center">
            <div className="col-3">
              <div
                className="mx-3 py-2"
                style={{
                  backgroundColor: "#ffffff20",
                  borderRadius: 5,
                  color: "#fff",
                  fontSize: 14,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => quitQuit()}
              >
                <IoIosClose className="clos" style={{ fontSize: 22 }} /> Quit
              </div>
            </div>
            <div
              className="col-4"
              style={{ color: "#fff", fontSize: 18, fontWeight: 600 }}
            >
              Score 00
            </div>
            <div className="col-2">
              <MdOutlineTranslate style={{ fontSize: 20, color: "#C5C5C5" }} />
            </div>
            <div
              className="col-3"
              style={{ color: "#fff", fontSize: 18, fontWeight: 600 }}
            >
              {formatTime(timeRemaining)}
            </div>
          </div>
          <div className="row g-0">
            <div
              className="col-12 p-0"
              style={{
                width: "100%",
                height: 8,
                background:
                  "linear-gradient(269.99deg, #59AFFF 0%, #6B24D6 99.99%)",
              }}
            ></div>
          </div>
          <div
            className="row px-3 py-3 d-flex justify-content-between align-items-center"
            style={{ backgroundColor: "#F3F3F3" }}
          >
            <div
              className="col-4"
              onClick={() => {
                if (click === 0) return false;
                setClick(click - 1);
              }}
              style={{
                fontSize: 14,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <TbArrowNarrowLeft style={{ fontSize: 20 }} />
              Previous
            </div>
            <div
              className="col-4"
              style={{ fontSize: 22, fontWeight: 600, color: "#2E2E2E" }}
            >
              {click + 1}/
              <span style={{ fontSize: 20, fontWeight: 600, color: "#7E7E7E" }}>
                {question?.length}
              </span>
            </div>
            <div
              className="col-4"
              onClick={() => {
                if (click === question.length - 1) return false;
                Next();
              }}
              style={{
                fontSize: 14,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              Next
              <TbArrowNarrowRight style={{ fontSize: 20 }} />
            </div>
          </div>
        </div>
        <div
          className="que-screen"
          style={{ marginTop: "140px", marginBottom: "120px" }}
        >
          <div className="row p-3">
            <div className="col-12 mt-3 text-start">
              <div className="card border-0 bg-transparent">
                {
                  question[click]?.is_ques_img == 1 ?
                    <>
                      <p className="assi-declr-txt" style={{ fontSize: 18, fontWeight: 600, color: "#2E2E2E", fontFamily: "inter" }}>
                        {question[click]?.question}
                      </p>
                      <img src={question[click].question_url} alt="Not Available" />
                    </>

                    :
                    <p className="assi-declr-txt" style={{ fontSize: 18, fontWeight: 600, color: "#2E2E2E", fontFamily: "inter" }}>
                      {question[click]?.question}
                    </p>
                }

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-11 m-auto">
              {know === 1 ? (
                <form>
                  <div className="form-check mb-3" style={{ textAlign: "start" }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`flexRadioDefault${click}`}
                      id={`flexRadioDefault1-${click}`}
                      checked={selectedOptions[click] === 1}
                      onChange={() => handleOptionClick(1)}
                    />
                    <div className="card border-0 bg-transparent">
                      <label className="form-check-label text-start" htmlFor={`flexRadioDefault1-${click}`}>
                        {
                          question[click]?.is_opt_img == 1 ?
                            <img src={question[click]?.option1} alt="Not Available" width={100}/>
                            :
                            <>
                              {question[click]?.option1}
                            </>
                        }

                      </label>
                    </div>
                  </div>
                  <div className="form-check mb-3" style={{ textAlign: "start" }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`flexRadioDefault${click}`}
                      id={`flexRadioDefault2-${click}`}
                      checked={selectedOptions[click] === 2}
                      onChange={() => handleOptionClick(2)}
                    />
                    <label className="form-check-label text-start" htmlFor={`flexRadioDefault2-${click}`}>
                      {
                        question[click]?.is_opt_img == 1 ?
                          <img src={question[click]?.option2} alt="Not Available" width={100}/>
                          :
                          <>
                            {question[click]?.option2}
                          </>
                      }
                    </label>
                  </div>
                  <div className="form-check mb-3" style={{ textAlign: "start" }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`flexRadioDefault${click}`}
                      id={`flexRadioDefault3-${click}`}
                      checked={selectedOptions[click] === 3}
                      onChange={() => handleOptionClick(3)}
                    />
                    <label className="form-check-label text-start" htmlFor={`flexRadioDefault3-${click}`}>
                      {
                        question[click]?.is_opt_img == 1 ?
                          <img src={question[click]?.option3} alt="Not Available" width={100}/>
                          :
                          <>
                            {question[click]?.option3}
                          </>
                      }
                    </label>
                  </div>
                  <div className="form-check mb-3" style={{ textAlign: "start" }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`flexRadioDefault${click}`}
                      id={`flexRadioDefault4-${click}`}
                      checked={selectedOptions[click] === 4}
                      onChange={() => handleOptionClick(4)}
                    />
                    <label className="form-check-label text-start" htmlFor={`flexRadioDefault4-${click}`}>
                      {
                        question[click]?.is_opt_img == 1 ?
                          <img src={question[click]?.option4} alt="Not Available" width={100}/>
                          :
                          <>
                            {question[click]?.option4}
                          </>
                      }
                    </label>
                  </div>
                </form>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {
          click === question.length - 1 ?
            <div
              onClick={() => {
                SaveNext()
              }}
              className="row px-4 py-4 fixed-bottom text-decoration-none"
            >
              <div
                className=" ms-auto py-3"
                style={{
                  backgroundColor: "#ED7A2B",
                  borderRadius: 5,
                  color: "#fff",
                  fontSize: 16,
                  fontWeight: 600,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                Submit
              </div>
            </div> :
            <div
              className="row fixed-bottom px-4 py-4"
              style={{ backgroundColor: "#fff", alignItems: "center" }}
            >
              <div
                onClick={() => HandelSkip()}
                className="col-4 py-3"
                style={{
                  backgroundColor: "#F2F2F2",
                  borderRadius: 5,
                  color: "#8A8C94",
                  fontSize: 16,
                  fontWeight: 600,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                Skip
              </div>

              <div
                onClick={() => {
                  SaveNext();
                }}
                className="text-decoration-none col-8"
              >
                <div
                  className=" ms-auto py-3"
                  style={{
                    backgroundColor: "#ED7A2B",
                    borderRadius: 5,
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: 600,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  Save & Next
                </div>
              </div>
            </div>
        }


      </div>
    </div >
  );
}
