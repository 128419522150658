import { useEffect, useState } from "react";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { API_URL } from "../env";
import { Loader } from "./Loader";

export default function ComplainHistory() {
  const [exam, setExam] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const examData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("school_token")}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };
    setIsLoading(true)
    fetch(`${API_URL}/student/complain/solved/complain`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsLoading(false)
        if (result.status == 1) {
          setExam(result.complains);
        }
      })
      .catch(error => console.log('error', error));
  };

  useEffect(() => {
    examData();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-3">
            {
              isLoading ? <Loader show={isLoading} /> :
                <>
                  {
                    exam.length > 0 ? (
                      exam?.map((item, index) => {
                        return (
                          <>
                            <div key={index} className="card p-3 border-0 mb-3 ">
                              <div className="row d-flex justify-content-between align-items-center">
                                <div className="col-10">
                                  <h5 className="text-start homelive-txt"
                                  style={{  textTransform:'capitalize'}}
                                  >
                                    {item.head}
                                  </h5>
                                  <div
                                    className=""
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="d-flex align-items-center py-2">
                                      <MdOutlineCalendarMonth
                                        className="sub-assi-txt"
                                        style={{ fontSize: 25, color: "#777C94" }}
                                      />
                                      <div
                                        className="sub-assi-txt ps-2"
                                        style={{
                                          fontSize: 18,
                                          fontWeight: 600,
                                          color: "#777C94",
                                        }}
                                      >
                                        {item.create_on}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-2 px-2">
                                <div
                                  className="sub-assi-txt text-center ps-2 col-5 p-1"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#0CBC8B",
                                    backgroundColor: "#E1FFEF",
                                    borderRadius: 5,
                                  }}
                                >
                                  {item.solve_on}
                                </div>
                              </div>
                              <div
                                className="col-12 text-start pt-2"
                                style={{
                                  fontSize: 16,
                                  fontWeight: 400,
                                  color: "#9098A8",
                                }}
                              >
                                Solved By
                              </div>
                              <div className="row px-2 mt-2">
                                <div
                                  className="col-8"
                                  style={{
                                    border: "1px solid #9098A8",
                                    borderRadius: 10,
                                  }}
                                >
                                  <div className="row p-2 ">
                                 
                                    <div
                                      className="col-10 text-center homelive-txt"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#545454",
                                        fontSize: 16,
                                        textTransform:'capitalize'
                                      }}
                                    >
                                      {item.solved_by}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-10">
                                  <div className="row mt-3">
                                    <NavLink
                                      to={`/ComplainDetail/${item.id}`}
                                      className="text-decoration-none col-5"
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-outline py-2 sub-assi-txt"
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#367CFF",
                                          color: "#FFF",
                                          borderRadius: 10,
                                        }}
                                      >
                                        View Details
                                      </button>
                                    </NavLink>
                                    {/* <NavLink
                                      to="/ChatHistory"
                                      className="text-decoration-none col-7"
                                    >
                                      <button
                                        type="button"
                                        className="btn py-2 sub-assi-txt"
                                        style={{
                                          width: "100%",
                                          color: "#367CFF",
                                          backgroundColor: "#fff",
                                          borderRadius: 10,
                                          border: "1px solid #367CFF",
                                        }}
                                      >
                                        Open Chat History
                                      </button>
                                    </NavLink> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <>
                        <div className="mt-5" style={{ color: "red" }}>
                          <h6>0 Complain Solved</h6>
                        </div>
                      </>
                    )
                  }
                </>

            }

          </div>
        </div>
        <div
          className="row fixed-bottom px-4 py-4"
          style={{ backgroundColor: "transparent", alignItems: "center" }}
        >
          <NavLink
            to="/AddComplaints"
            className="text-decoration-none col-3 ms-auto"
          >
            <div
              className="ms-auto"
              style={{
                width: 80,
                height: 80,
                backgroundColor: "#ED7A2B",
                borderRadius: 20,
                color: "#fff",
                fontSize: 35,
                fontWeight: 600,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                cursor: "pointer",
              }}
            >
              +
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
