import React, { useEffect, useState } from "react";
import { ImArrowLeft2 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { BiLogInCircle  } from "react-icons/bi";
import { MdOutlineDateRange } from "react-icons/md";
import { API_URL } from "../env";

const Attendance = () => {
  const navigate = useNavigate();



  const [attendanceData, setAttendanceData] = useState([]);
  const getData =()=>{
    try{
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("school_token")}`
      );
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${API_URL}/student/attendence/get/attendence/list`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          setAttendanceData(result.attendence_list);
        } else {
          console.error( result.message);
        }
      })
  .catch(error => console.log('error', error));

    }catch(e){
      console.log(e);
    }

  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        className="container"
      >
        <div className="row sticky-top top-0">
          <div className="col-12 p-2">
            <div className="d-flex align-items-center justify-content-between">
              <button
                type="button"
                  onClick={() => navigate("/Home")}
                className="btn border-0"
                style={{
                  height: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImArrowLeft2 style={{ fontSize: 24 }} />
              </button>
              <span
                className="text-start me-4"
                style={{ fontWeight: 600, fontSize: 25 }}
              >
                Attendance
              </span>
              <span></span>
            </div>
          </div>
        </div>

        <div className="row py-5 gap-3" style={{overflowY:"auto"}} >
        {
          attendanceData.map((value,index)=>(
          <div className="col-12" key={index} >
            <div className="card p-3">
              <div className="d-flex justify-content-between align-items-center" >
              <div className="mb-0 d-flex align-items-center" >
                <MdOutlineDateRange />
                <span className="ms-1" > {value.attendence_date}</span>
              </div>
                <span className="mb-0 px-2 py-1"  style={{
                background:
                  value.status === "Present"
                    ? "#DBFFF0"
                    : value.status === "half_day"
                    ? "#F0FAC4"
                    : value.status === "Absent"
                    ? "#FFE9E9"
                    : "white",
                color:
                  value.status === "Present"
                    ? "#359A73"
                    : value.status === "half_day"
                    ? "#C3BC16"
                    : value.status === "Absent"
                    ? "#E91C1C"
                    : "black",
                    borderRadius:"50px"
              }} > {value.status}</span>
              </div>
              <hr/>
              <div className="d-flex justify-content-between align-items-center" >

              <div className="mb-0 d-flex align-items-center" >
                <BiLogInCircle  />
                <span className="ms-1">
                {value.in_time}</span>
              </div>
                <div className="mb-0 d-flex align-items-center">
                <BiLogInCircle style={{transform:"rotate( 180deg)"}}  />
                <span className="ms-1">{value.out_time}</span>
                </div>
              </div>
            </div>
          </div>
          ))
        }
        </div>
      </div>
    </>
  );
};

export default Attendance;
