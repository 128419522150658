import {
  AUTH_SIGN_IN_ERROR,
  AUTH_SIGN_IN_LOADING,
  AUTH_SIGN_IN_SUCCESS,
  AUTH_SIGN_OUT,
} from "./auth.types";
import { instance } from "../../instance";
import { redirect } from "react-router-dom";
import { toast } from "react-toastify";

export const authsigninAPI = (creds) => async (dispatch) => {
  dispatch({ type: AUTH_SIGN_IN_LOADING });
  try {
    let res = await instance.post("student/authentication/login", creds);
    if (res.data.status == 1) {
      dispatch({ type: AUTH_SIGN_IN_SUCCESS, payload: res.data });
      toast("Login Succesfull!")
      return redirect("/Home");
   
    }
    else if (res.data.status === "CUSTOM_ERR" || res.data.status ==="FILE_ERR" || res.data.status==="VAL_ERR") {
      toast(res.data.Backend_Error);
      // console.log(res.data.Backend_Error,'in else if');
    }
    else {
      toast("Something went wrong");
      console.log(res.data,'in else');
    }

    return res.data;
  } catch (err) {
    dispatch({ type: AUTH_SIGN_IN_ERROR });
  }
};

export const AuthLogoutAPI = () => async (dispatch) => {
  try {
    let res = await instance.post("student/authentication/logout");
    if (res.data.status == 1) {
      dispatch({ type: AUTH_SIGN_OUT });
    }
  } catch (err) {
    console.log(err, "error");
  }
};
