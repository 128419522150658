import { NavLink, useNavigate, useParams } from "react-router-dom";
import { API_URL } from '../env';
import { useState } from "react";
import { useEffect } from "react";
export default function ReviewAnswer() {
  const navigate = useNavigate();
  const [overView, setOverview] = useState([]);
  const { quiz_id } = useParams();

  const reviewResponse = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("school_token")}`);

    var raw = JSON.stringify({
      "quiz_id": quiz_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/quiz/view/quiz/after/submit`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status == 1) {
          setOverview([result.over_view]);
        }
      })
      .catch(error => console.log('error', error));
  }

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsStr = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${minutesStr} min : ${secondsStr} sec`;
  }

  useEffect(() => {
    reviewResponse();
  }, [])

  return (
    <div>
      {
        overView.map((item, index) => {
          return (
            <>
              <div className="container" style={{ overflowX: "hidden" }} key={index}>
                <div className="row">
                  <div className="col-10 m-auto">
                    <p
                      className="pt-5"
                      style={{ color: "#2E2E2E", fontSize: 26, fontWeight: 600 }}
                    >
                      Review of Submission
                    </p>
                    <div className="card p-3 border-0">
                      <div className="row mt-3">
                        <div className="col-7 text-start">
                          <div
                            style={{ fontSize: 12, fontWeight: 600, color: "#7E7E7E" }}
                          >
                            Total Questions
                          </div>
                          <div
                            className="text-start"
                            style={{ color: "#2E2E2E", fontSize: 20, fontWeight: 600 }}
                          >
                            {item.total_no_of_ques}
                          </div>
                        </div>
                        <div className="col-5 text-start">
                          <div
                            style={{ fontSize: 12, fontWeight: 600, color: "#7E7E7E" }}
                          >
                            Correct
                          </div>
                          <div
                            className=""
                            style={{ color: "#0CBC8B", fontSize: 20, fontWeight: 600 }}
                          >
                            {item.correct}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-7 text-start">
                          <div
                            style={{ fontSize: 12, fontWeight: 600, color: "#7E7E7E" }}
                          >
                            Unattempt
                          </div>
                          <div
                            className="text-start"
                            style={{ color: "#7E7E7E", fontSize: 20, fontWeight: 600 }}
                          >
                            {item.unattempt}
                          </div>
                        </div>
                        <div className="col-5 text-start">
                          <div
                            style={{ fontSize: 12, fontWeight: 600, color: "#7E7E7E" }}
                          >
                            Incorrect
                          </div>
                          <div
                            className=""
                            style={{ color: "#DC1111", fontSize: 20, fontWeight: 600 }}
                          >
                            {item.incorrect}
                          </div>
                        </div>
                      </div>
                      <NavLink
                        to="/Home"
                        className="text-decoration-none col-12 mt-3"
                      >
                        <div
                          className="ms-auto py-3"
                          style={{
                            backgroundColor: "#ED7A2B",
                            borderRadius: 5,
                            color: "#fff",
                            fontSize: 16,
                            fontWeight: 600,
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            cursor: "pointer",
                          }}
                        >
                          Back to Home
                        </div>
                      </NavLink>
                      {/* <div
                className="py-3 mt-3"
                onClick={() => navigate(-1)}
                style={{
                  backgroundColor: "#F2F2F2",
                  borderRadius: 5,
                  color: "#2E2E2E",
                  fontSize: 16,
                  fontWeight: 600,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                Back
              </div> */}
                    </div>
                    <div
                      className="card mt-5 border-0 py-3"
                      style={{ backgroundColor: "#F5F5F5" }}
                    >
                      <div className="row g-0 d-flex align-items-center justify-content-center">
                        <div className="">
                          <div
                            style={{ fontSize: 22, fontWeight: 600, color: "#8A8C94" }}
                          >
                            {formatTime(item.submit_timeperiod)}
                          </div>
                          {/* <div
                            style={{ fontSize: 16, fontWeight: 600, color: "#8A8C94" }}
                          >
                            Mins
                          </div> */}
                        </div>
                        {/* <div
                          className="col-2"
                          style={{
                            fontSize: 50,
                            fontWeight: "bolder",
                            color: "#8A8C94",
                          }}
                        >
                          :
                        </div> */}
                        {/* <div className="col-5 text-start">
                          <div
                            style={{ fontSize: 22, fontWeight: 600, color: "#8A8C94" }}
                          >
                            25
                          </div>
                          <div
                            style={{ fontSize: 16, fontWeight: 600, color: "#8A8C94" }}
                          >
                            Sec
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {/* <p
              className="px-4 pt-3 up-assi-txt"
              style={{ color: "#7E7E7E", fontSize: 16, fontWeight: 600 }}
            >
              Answers will be automatically submitted in
            </p> */}
                    <p
                      className="pt-5 up-assi-txt"
                      style={{ color: "#D92828", fontSize: 16, fontWeight: 600 }}
                    >
                      Note : Answers once submitted can not be changed or filled up
                    </p>
                  </div>
                </div>
              </div>
            </>
          )
        })
      }

    </div>
  );
}
