export const VideoPlayer = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V5.2L21.213 1.55C21.288 1.49746 21.3759 1.4665 21.4672 1.4605C21.5586 1.4545 21.6498 1.4737 21.731 1.51599C21.8122 1.55829 21.8802 1.62206 21.9276 1.70035C21.9751 1.77865 22.0001 1.86846 22 1.96V14.04C22.0001 14.1315 21.9751 14.2214 21.9276 14.2996C21.8802 14.3779 21.8122 14.4417 21.731 14.484C21.6498 14.5263 21.5586 14.5455 21.4672 14.5395C21.3759 14.5335 21.288 14.5025 21.213 14.45L16 10.8V15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15ZM6.4 4.829C6.30779 4.82898 6.2184 4.86082 6.14697 4.91914C6.07553 4.97745 6.02644 5.05865 6.008 5.149L6 5.228V10.77C5.99999 10.8348 6.01572 10.8986 6.04582 10.9559C6.07592 11.0133 6.11951 11.0625 6.17282 11.0993C6.22614 11.1361 6.28759 11.1594 6.3519 11.1672C6.4162 11.1749 6.48144 11.167 6.542 11.144L6.615 11.108L10.97 8.337C11.0208 8.30438 11.0636 8.26071 11.0952 8.20923C11.1268 8.15775 11.1464 8.09981 11.1524 8.03971C11.1585 7.97962 11.1509 7.91894 11.1302 7.86219C11.1095 7.80545 11.0763 7.7541 11.033 7.712L10.97 7.662L6.615 4.89C6.55048 4.85 6.47591 4.82819 6.4 4.829Z"
        fill="white"
      />
    </svg>
  );
};
export const Document = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 13V5.749C20.0001 5.67006 19.9845 5.59189 19.9543 5.51896C19.9241 5.44603 19.8798 5.37978 19.824 5.324L16.676 2.176C16.5636 2.06345 16.4111 2.00014 16.252 2H4.6C4.44087 2 4.28826 2.06321 4.17574 2.17574C4.06321 2.28826 4 2.44087 4 2.6V21.4C4 21.5591 4.06321 21.7117 4.17574 21.8243C4.28826 21.9368 4.44087 22 4.6 22H14"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 2V5.4C16 5.55913 16.0632 5.71174 16.1757 5.82426C16.2883 5.93679 16.4409 6 16.6 6H20M16 19H22M22 19L19 16M22 19L19 22"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Download = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 16C3.98333 16 2.68767 15.475 1.613 14.425C0.537667 13.375 0 12.0917 0 10.575C0 9.27501 0.391667 8.11667 1.175 7.10001C1.95833 6.08334 2.98333 5.43334 4.25 5.15001C4.53333 3.95001 5.24167 2.80834 6.375 1.72501C7.50833 0.641673 8.71667 0.100006 10 0.100006C10.55 0.100006 11.021 0.295673 11.413 0.687006C11.8043 1.07901 12 1.55001 12 2.10001V8.15001L12.9 7.27501C13.0833 7.09167 13.3127 7.00001 13.588 7.00001C13.8627 7.00001 14.1 7.10001 14.3 7.30001C14.4833 7.48334 14.575 7.71667 14.575 8.00001C14.575 8.28334 14.4833 8.51667 14.3 8.70001L11.7 11.3C11.6 11.4 11.4917 11.4707 11.375 11.512C11.2583 11.554 11.1333 11.575 11 11.575C10.8667 11.575 10.7417 11.554 10.625 11.512C10.5083 11.4707 10.4 11.4 10.3 11.3L7.7 8.70001C7.51667 8.51667 7.421 8.28734 7.413 8.01201C7.40433 7.73734 7.5 7.50001 7.7 7.30001C7.88333 7.11667 8.11233 7.02067 8.387 7.01201C8.66233 7.00401 8.9 7.09167 9.1 7.27501L10 8.15001V2.10001C8.73333 2.33334 7.75 2.94567 7.05 3.93701C6.35 4.92901 6 5.95001 6 7.00001H5.5C4.53333 7.00001 3.70833 7.34167 3.025 8.02501C2.34167 8.70834 2 9.53334 2 10.5C2 11.4667 2.34167 12.2917 3.025 12.975C3.70833 13.6583 4.53333 14 5.5 14H17.5C18.2 14 18.7917 13.7583 19.275 13.275C19.7583 12.7917 20 12.2 20 11.5C20 10.8 19.7583 10.2083 19.275 9.72501C18.7917 9.24167 18.2 9.00001 17.5 9.00001H16V7.00001C16 6.20001 15.8167 5.45401 15.45 4.76201C15.0833 4.07067 14.6 3.48334 14 3.00001V0.675006C15.2333 1.25834 16.2083 2.12101 16.925 3.26301C17.6417 4.40434 18 5.65001 18 7.00001C19.15 7.13334 20.1043 7.62901 20.863 8.48701C21.621 9.34567 22 10.35 22 11.5C22 12.75 21.5627 13.8127 20.688 14.688C19.8127 15.5627 18.75 16 17.5 16H5.5Z"
        fill="black"
      />
    </svg>
  );
};
export const Fees = () => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 17.5H15.25C15.44 17.5001 15.6229 17.5722 15.7618 17.702C15.9006 17.8317 15.9851 18.0093 15.998 18.1989C16.011 18.3885 15.9515 18.5759 15.8316 18.7233C15.7117 18.8707 15.5402 18.9671 15.352 18.993L15.25 19H0.75C0.559977 18.9999 0.377059 18.9278 0.238208 18.798C0.0993575 18.6683 0.0149261 18.4907 0.00197411 18.3011C-0.0109779 18.1115 0.0485155 17.9241 0.168433 17.7767C0.28835 17.6293 0.45975 17.5329 0.648 17.507L0.75 17.5ZM12.25 0C13.2446 0 14.1984 0.395088 14.9017 1.09835C15.6049 1.80161 16 2.75544 16 3.75V12.25C16 13.2446 15.6049 14.1984 14.9017 14.9017C14.1984 15.6049 13.2446 16 12.25 16H3.75C2.75544 16 1.80161 15.6049 1.09835 14.9017C0.395088 14.1984 0 13.2446 0 12.25V3.75C0 2.75544 0.395088 1.80161 1.09835 1.09835C1.80161 0.395088 2.75544 0 3.75 0H12.25Z"
        fill="#858585"
      />
    </svg>
  );
};
export const Send = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 16V10L8 8L0 6V0L19 8L0 16Z" fill="#2E2E2E" />
    </svg>
  );
};
