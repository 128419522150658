const Privacy_Policy = () => {
  return (
    <div>
      <p>Privacy Policy of Arise Education Point</p>

      <p>Effective Date: 30 July 2023</p>

      <p>
        At Arise Education Point, we are committed to protecting your privacy.
        This Privacy Policy outlines how we collect, use, disclose, and
        safeguard your personal information when you use our educational app
        (&quot;App&quot;) or interact with our services. By accessing or using
        the App, you acknowledge that you have read, understood, and agree to be
        bound by the terms of this Privacy Policy. If you do not agree with our
        practices, please do not use the App.
      </p>

      <p>
        Information We Collect: 1.1. Personal Information: When you use our App,
        we may collect certain personal information, including but not limited
        to your name, email address, and other contact details. We may also
        collect information related to your educational background and
        preferences.
      </p>

      <p>
        1.2. Usage Data: We may collect information about how you use the App,
        such as the pages you visit, the features you access, and the actions
        you take within the App. This may include information about your device,
        operating system, IP address, and browser type.
      </p>

      <p>
        How We Use Your Information: 2.1. Providing Educational Services: We use
        the information collected to deliver educational content, personalized
        learning experiences, and other related services.
      </p>

      <p>
        2.2. Communication: We may use your contact information to send you
        administrative messages, updates about the App, and promotional
        materials. You can opt-out of receiving promotional communications at
        any time.
      </p>

      <p>
        2.3. Improving the App: We analyze usage data to improve the
        functionality, features, and content of the App. This helps us enhance
        your learning experience and tailor the App to better suit your needs.
      </p>

      <p>
        2.4. Research and Analytics: Aggregated and anonymized data may be used
        for research and analytics purposes to understand trends and patterns in
        learning behavior. This data will not identify you personally.
      </p>

      <p>
        2.5. Legal Compliance: We may use your information as required by
        applicable laws, regulations, or legal proceedings.
      </p>

      <p>
        How We Share Your Information: 3.1. Service Providers: We may engage
        third-party service providers to assist us in operating the App and
        providing services. These providers will have access to your information
        solely for the purpose of performing their duties and will be bound by
        confidentiality obligations.
      </p>

      <p>
        3.2. Legal Requirements: We may disclose your information to comply with
        legal obligations, enforce our policies, or respond to lawful requests
        from public authorities.
      </p>

      <p>
        3.3. Business Transfers: In the event of a merger, acquisition, or sale
        of all or a portion of our assets, your information may be transferred
        as part of the transaction.
      </p>

      <p>
        Data Security: We implement reasonable security measures to protect your
        personal information from unauthorized access, disclosure, alteration,
        or destruction. However, please note that no method of data transmission
        over the internet or electronic storage is completely secure, and we
        cannot guarantee absolute security.
      </p>

      <p>
        Children's Privacy: Our App is not intended for children under the age
        of [minimum age defined by applicable data protection laws]. We do not
        knowingly collect personal information from children without verifiable
        parental consent. If we discover that we have unintentionally collected
        personal information from a child, we will take prompt steps to delete
        such information.
      </p>

      <p>
        Your Choices: You have the right to review, update, and delete your
        personal information from our records. You can also request to opt-out
        of receiving promotional communications from us. To exercise any of
        these rights, please contact us at [contact email address].
      </p>

      <p>
        Changes to this Privacy Policy: We may update this Privacy Policy from
        time to time to reflect changes in our practices or for other
        operational, legal, or regulatory reasons. The most recent version of
        the Privacy Policy will be posted on the App, and any changes will
        become effective when we post the updated Privacy Policy. Your continued
        use of the App after the posting of any modifications constitutes your
        acceptance of the revised Privacy Policy.
      </p>

      <p>
        Contact Us: If you have any questions, concerns, or feedback regarding
        this Privacy Policy or our data practices, please contact us at
        Arisechomu2013@gmail.com
      </p>
    </div>
  );
};

export default Privacy_Policy;
