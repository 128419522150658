
import {
    get_exam_data_error,
    get_exam_data_loading,
    get_exam_data_success
} from './exam.types'

const initState = {
    isLoading: false,
    isError: false,
    data: {
        examHistory:[]
    },
}

export const examReducer = (state = initState, { type, payload
}) => {
    switch (type) {
        case get_exam_data_loading: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case get_exam_data_error: {
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        }
        case get_exam_data_success: {
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    examHistory:payload
                },
            }
        }
        default:
            return state;
    }
}