import {
     get_upcoming_quiz_error, get_upcoming_quiz_loading, get_upcoming_quiz_success
} from './Quiz.types'

const initState = {
    isLoading:false,
    isErro:false,
    data:{
        historyQuiz:[],
        upcomingQuiz:[],
        quizHistory:[],
    },
};

export const quizReducer = (state = initState, {type, payload})=>{
    switch(type){
        case get_upcoming_quiz_loading:{
            return{
                ...state,
                isLoading:true,
            };
        }
        case get_upcoming_quiz_error:{
            return{
                ...state,
                isLoading:false,
                isErro:true,
            };
        }
        case get_upcoming_quiz_success:{
            return{
                ...state,
                isLoading:false,
          
                data:{
                    ...state.data,
                    upcomingQuiz:payload,
                    historyQuiz:payload,
                },
            };
        }

        default: return state;
    }
};