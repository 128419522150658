import { useState, useEffect } from "react";
import { HiMail } from "react-icons/hi";
import { AiFillEyeInvisible } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../env";
import { useDispatch, useSelector } from "react-redux";
import { authsigninAPI } from "../store/Auth/auth.actions";

export default function Login() {
  const { userName, isAuthenticated } = useSelector((store) => store.auth.data);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [first, setfirst] = useState(false);
  const [email, setEmail] = useState();
  const [LogoDat, setLogoDat] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  //function for handlechnage password
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };

  //function for toggle password
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const loginApp = () => {
    dispatch(
      authsigninAPI({
        enrollment_id: email,
        password: passwordInput,
      })
    );
  };

  const GetLogo = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${API_URL}/student/get/logo`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status == 1) {
          setLogoDat(result.logo[0]?.logo);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    // GetLogo();
    if (!localStorage.getItem("school_token")) {
      navigate("/Login");
    } else {
      navigate("/Home");
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 login-section">
            <div className="row pt-4 text-white">
              <div className="col-10 me-auto ps-4 pt-5 pb-4">
                <div className="text-start pt-5">
                  <h4>Login to your</h4>
                  <h1>Student App</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mx-auto">
            <div className="card login-card mt-5 bg-transparent">
              <p
                className="text-start"
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: "#545454",
                  fontFamily: "inter",
                }}
              >
                Enter Registered Email
              </p>
              <div
                class="input-group p-1 mb-3"
                style={{ border: "1px solid #E4E4E4", borderRadius: 5 }}
              >
                <span
                  className="input-group-text border-0 bg-transparent"
                  id="basic-addon1"
                >
                  <HiMail style={{ fontSize: 25, color: "#858585" }} />
                </span>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  className="form-control border-0 py-2 shadow-none bg-transparent"
                  placeholder="example@gmail.com"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div
                class="input-group p-1"
                style={{ border: "1px solid #E4E4E4", borderRadius: 5 }}
              >
                <span
                  className="input-group-text border-0 bg-transparent"
                  id="basic-addon1"
                >
                  <span
                    style={{ cursor: "pointer" }}
                    className="border-0"
                    onClick={togglePassword}
                  >
                    {passwordType == "password" ? (
                      <AiFillEyeInvisible
                        style={{ fontSize: 25, color: "#858585" }}
                      />
                    ) : (
                      <AiFillEye style={{ fontSize: 25, color: "#858585" }} />
                    )}
                  </span>
                </span>
                <input
                  type={passwordType}
                  onChange={handlePasswordChange}
                  value={passwordInput}
                  name="password"
                  className="form-control border-0 py-2 shadow-none bg-transparent"
                  placeholder="Enter Password"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>

              <div onClick={loginApp} className="log-btn p-3 mt-3 text-white">
                {first == true ? "Loading..." : "Login"}
              </div>

              {/* <NavLink
                to="/Privacy_Policy"
                className="text-decoration-none d-flex w-100"
                style={{ textAlign: "start" }}
              >
                <span
                  className="d-flex"
                  style={{ cursor: "pointer", color: "#808080" }}
                >
                  by logging in, you agree to our&nbsp;
                  <p style={{ textDecoration: "underline", color: "#808" }}>
                    privacy policy
                  </p>
                </span>
              </NavLink> */}

              {/* <div className="col-10 mt-4 m-auto">
                <img
                  src={LogoDat}
                  alt="#"
                  className=""
                  style={{ width: "100%" }}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={5000} />
    </>
  );
}
