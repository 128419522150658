import Footer from "./Footer";
import Header from "./Header";

const ComingSoon = () => {
  return (
    <>
      <Header />
      <div
        style={{
          marginTop: "80px",
        }}
      >
        <h1>Coming Soon</h1>
      </div>
      <Footer />
    </>
  );
};

export default ComingSoon;
