import { useSelector } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";

const useFetch = (endpoint, method, payload) => {
  const { token } = useSelector((store) => store.auth.data);
  const [res, setRes] = useState({});
  const frontend_url = window.location.hostname;
  const instance = axios.create({
    baseURL:
      frontend_url === "localhost"
        ? localStorage.getItem("student_url")
        : localStorage.getItem("student_url"),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const Hit = async (method, endpoint, payload) => {
    try {
      let response = await instance[method](endpoint, payload);
      setRes(response.data);
    } catch (err) {
      setRes({
        error: "Internal Server Error",
        descripation: `${err}`,
      });
    }
  };

  useEffect(() => {
    Hit(method, endpoint, payload);
  }, []);

  return {
    ...res,
  };
};

export default useFetch;
