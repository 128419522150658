import {
  get_home_data_error,
  get_home_data_loading,
  get_home_data_success,
} from "./home.types";

const initState = {
  isLoading: false,
  isError: false,
  data: [],
};

export const homeReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case get_home_data_loading: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case get_home_data_error: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }

    case get_home_data_success: {
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    }

    default:
      return state;
  }
};
