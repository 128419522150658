export const get_upcoming_assignment_success = "get/upcoming/assignment/success";
export const get_history_assignment_success = "get/history/assignment/success";
export const get_selected_assignment_success = "get/seleted/assignment/success";

export const get_upcoming_assignment_error = "get/upcoming/assignment/error";
export const get_history_assignment_error = "get/history/assignment/error";
export const get_selected_assignment_error = "get/seleted/assignment/error";

export const get_upcoming_assignment_loading = "get/upcoming/assignment/loading";
export const get_history_assignment_loading = "get/history/assignment/loading";
export const get_selected_assignment_loading = "get/seleted/assignment/loading";
