import axios from "axios";
import { store } from "./store/store";

// const token = localStorage.getItem("token");
// const frontend_url = window.location.hostname;
const frontend_url =  localStorage.getItem("student_url");
const { token } = store.getState().auth.data; // Get the current state of the Redux store
//console.log(token, "in instance");
//function for instances created using axios
export const instance = axios.create({
  baseURL:
    frontend_url === "localhost"
      ? localStorage.getItem("student_url")
      : localStorage.getItem("student_url"),
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

//function for image generator by their file name
export const imageGenertor = (filename) => {
  let image =
    frontend_url === "localhost"
      ? `http://localhost:8080/public/institutes/${filename}`
      : `https://masterapi.intellix360.in/public/institutes/${filename}`;
  return image;
};
