import {
  get_home_data_error,
  get_home_data_loading,
  get_home_data_success,
} from "./home.types";
import { instance } from "../../instance";

export const getHomeDataApi = () => async (dispatch) => {
  dispatch({ type: get_home_data_loading });
  try {
    let res = await instance.post("student/dashboard/home");
    if (res.data.status == 1) {
      console.log(res.data, "data in store from home page");
      dispatch({ type: get_home_data_success, payload: res.data.sending });
    }
  } catch (err) {
    dispatch({ type: get_home_data_error });
  }
};
