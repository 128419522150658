import { instance } from "../../instance";
import {
    get_history_assignment_error,
    get_history_assignment_loading,
  get_history_assignment_success,
  get_selected_assignment_error,
  get_selected_assignment_loading,
  get_selected_assignment_success,
  get_upcoming_assignment_error,
  get_upcoming_assignment_loading,
  get_upcoming_assignment_success,
} from "./assignment.types";

// export const getUpcomingAssignmentApi = () => async (dispatch) => {
//     dispatch({ type: get_upcoming_assignment_loading });
//     try {
        
//       dispatch({ type: get_upcoming_assignment_success, payload: res.data });
//     } catch (err) {
//       dispatch({ type: get_upcoming_assignment_error });
//     }
// };

// export const getHistoryAssignmentApi = () => async (dispatch) => {
//     dispatch({ type: get_history_assignment_loading });
//     try {
//       dispatch({ type: get_history_assignment_success, payload: res.data });
//     } catch (err) {
//       dispatch({ type: get_history_assignment_error });
//     }
// };

// export const getSelectedAssignmentApi = () => async (dispatch) => {
//   dispatch({ type: get_selected_assignment_loading });
//   try {
//     dispatch({ type: get_selected_assignment_success, payload: res.data });
//   } catch (err) {
//     dispatch({ type: get_selected_assignment_error });
//   }
// };

export const getAssignmentByStatus = (status) => async (dispatch)=>{
  dispatch({type : get_history_assignment_loading});
  try{
    let res = await instance.post("student/profile/get/assignment/bystatus",{
      status: status,
    });
    if(res.data.status==1){
      dispatch({type : get_history_assignment_success, payload:res.data.assignment_list});
    }
   
  }catch(err){
    dispatch({type:get_history_assignment_error});
  }
}
