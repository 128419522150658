import {
  get_history_assignment_error,
  get_history_assignment_loading,
  get_history_assignment_success,
  get_selected_assignment_error,
  get_selected_assignment_loading,
  get_selected_assignment_success,
  get_upcoming_assignment_error,
  get_upcoming_assignment_loading,
  get_upcoming_assignment_success,
} from "./assignment.types";

const initState = {
  isLoading: false,
  isError: false,
  data: {
    assignmentHistory:[]
  },
};
export const assignmentReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case get_upcoming_assignment_loading: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case get_upcoming_assignment_error: {
      return {
        ...state,
        isError: true,
      };
    }
    case get_upcoming_assignment_success: {
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    }

    case get_history_assignment_success: {
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          assignmentHistory:payload
        },
      };
    }

    case get_history_assignment_loading: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case get_history_assignment_error: {
      return {
        ...state,
        isError: true,
      };
    }

    case get_selected_assignment_success: {
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    }

    case get_selected_assignment_loading: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case get_selected_assignment_error: {
      return {
        ...state,
        isError: false,
      };
    }

    default:
      return state;
  }
};
