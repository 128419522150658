import { useEffect, useState } from "react";
import { Fees } from "./SvgIcon";
import { AiTwotoneCalendar } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { API_URL } from "../env";
import { Loader } from "./Loader";

export default function Overdue() {
  const [payment, setPayment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const Api = async () => {
    var myHeaders = new Headers();
    let Token = await localStorage.getItem("school_token");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
setIsLoading(true);
    fetch(`${API_URL}/student/payment/get/missed/payment`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)
        console.log(result);

        if (result.status == 1) {
          setPayment(result.due_payment);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    Api();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
          {
            isLoading ? <Loader show={isLoading}/>:
            <>
            {payment.length > 0 ? (
              payment?.map((item, index) => {
                return (
                  <>
                    <div key={index} className="card mt-3 p-3 border-0">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            color: "#858585",
                            fontSize: 16,
                            fontWeight: 400,
                            fontFamily: "inter",
                          }}
                        >
                          Amount
                        </p>
                        <p
                          className="px-3 p-1 sub-assi-txt"
                          style={{
                            color: "#FF0E0E",
                            fontSize: 14,
                            fontWeight: 400,
                            backgroundColor: "#FFE1E1",
                            borderRadius: 5,
                          }}
                        >
                          Unpaid
                        </p>
                      </div>
                      <div
                        className="text-start m-0"
                        style={{
                          color: "#545454",
                          fontSize: 26,
                          fontWeight: 600,
                          fontFamily: "inter",
                        }}
                      >
                        ₹ {item.amount}
                      </div>
                      <div className="d-flex align-items-center">
                        <AiTwotoneCalendar
                          className="sub-assi-txt"
                          style={{ fontSize: 24, color: "#858585" }}
                        />
                        <div
                          className="sub-assi-txt ps-2"
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#858585",
                            fontFamily: "inter",
                          }}
                        >
                          Due Date : {item.due_date}
                        </div>
                      </div>
                      <div className="py-2 d-flex align-items-center">
                        <Fees
                          className="sub-assi-txt"
                          style={{ fontSize: 24, color: "#FF0E0E" }}
                        />
                        <div
                          className="sub-assi-txt ps-3"
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#FF0E0E",
                            fontFamily: "inter",
                          }}
                        >
                          {item.remark}
                        </div>
                      </div>
                      {/* <div className="row mt-2">
                        <NavLink
                          to={`/PaymentDetail/${item.invoice_id}`}
                          className="text-decoration-none col-6"
                        >
                          <button
                            type="button"
                            className="btn btn-outline py-3 sub-assi-txt"
                            style={{
                              width: "100%",
                              border: "1px solid #ED7A2B",
                              color: "#ED7A2B",
                              borderRadius: 5,
                              fontFamily: "inter",
                            }}
                          >
                            View Details
                          </button>
                        </NavLink>

                        <NavLink className="text-decoration-none col-6">
                          <button
                            type="button"
                            className="btn py-3 sub-assi-txt"
                            style={{
                              width: "100%",
                              color: "#fff",
                              backgroundColor: "#ED7A2B",
                              borderRadius: 5,
                              fontFamily: "inter",
                            }}
                          >
                            Pay Now
                          </button>
                        </NavLink>
                      </div> */}
                    </div>
                  </>
                );
              })
            ) : (
              <>
                <div className="mt-5" style={{ color: "red" }}>
                  <h6>You had done all your payments</h6>
                </div>
              </>
            )}
            </>
          }
           
          </div>
        </div>
      </div>
    </div>
  );
}
