import { useEffect, useState } from "react";
import { ImArrowLeft2 } from "react-icons/im";
import { AiTwotoneCalendar } from "react-icons/ai";
import { CiClock2 } from "react-icons/ci";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../env";
import { FiUpload } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "./Loader";

export default function ExamDetail() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState();
  const [ImageSave, setImageSave] = useState();
  const [stu_res, setstu_res] = useState("");
  const [exam_name, setexam_name] = useState();
  const [cou_name, setcou_name] = useState();
  const [sub_name, setsub_name] = useState();
  const [start_time, setstart_time] = useState();
  const [tot_marks, settot_marks] = useState();
  const [end_time, setend_time] = useState();
  const [ques_paper, setques_paper] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getExamDetail = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("school_token")}`
    );

    var raw = JSON.stringify({
      exam_id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setIsLoading(true);
    fetch(`${API_URL}/student/exams/view/upcom/exam`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)
        if (result.status == "1") {
          console.log(result);
         
          setexam_name(result.exam.exam_name);
          setcou_name(result.exam.cou_name);
          setsub_name(result.exam.sub_name);
          setstart_time(result.exam.start_time);
          setend_time(result.exam.end_time);
          settot_marks(result.exam.tot_marks);
          setstu_res(result.exam.stu_res)
          setques_paper(result.exam.ques_pap)
        }
        else {
          setIsLoading(false)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const submit = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("school_token")}`);

    var formdata = new FormData();
    formdata.append("exam_id", id);
    formdata.append("pdf", ImageSave);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/exams/submit/pdf/by/student/inexam`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status == 1) {
          toast(result.msg)
        }

        else {
          toast(result.message);
          toast(result.Backend_Error);
        }
      })
      .catch(error => console.log('error', error));
  }

  const handleImageChange = (e) => {
    console.log(e.target.files);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
    setImageSave(e.target.files[0]);
    setstu_res(e.target.files[0].name);
  };

  useEffect(() => {
    getExamDetail();
    if (!localStorage.getItem("school_token")) {
    } else {
    }
  }, []);

  console.log(stu_res, 'student')

  return (
    <div>
      {
        isLoading ? <Loader show={isLoading} /> :
          <>
            <ToastContainer />
            <div className="container-fluid p-0" style={{ overflowX: "hidden" }}>
              <div
                className="p-0"
                style={{ width: "100%", marginTop: "40px", marginBottom: "90px" }}
              >
                <div className="row fixed-top">
                  <div className="col-md-12">
                    <div className="card py-2 rounded-0 border-0">
                      <div className="row py-3">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "",
                            alignItems: "center",
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => navigate(-1)}
                            className="btn border-0"
                            style={{
                              height: 30,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ImArrowLeft2 style={{ fontSize: 20 }} />
                          </button>
                          <span
                            className="assi-nav-txt"
                            style={{ fontWeight: 600, fontSize: 20, paddingLeft: 10 }}
                          >
                            Exam Details
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bar-option pb-5">
                <div className="row p-4">
                  <div className="col-12">
                    <div
                      className="text-start"
                      style={{ fontSize: 18, color: "#545454", fontWeight: 400 }}
                    >
                      Name of Exam
                    </div>
                    <div
                      className="text-start pt-2 assi-nav-txt"
                      style={{ fontSize: 22, fontWeight: 600, color: "#1E1E1E" }}
                    >
                      {exam_name}
                    </div>
                    <div
                      className="pt-4"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <AiTwotoneCalendar
                          className="sub-assi-txt"
                          style={{ fontSize: 20, color: "#545454" }}
                        />
                        <div
                          className="sub-assi-txt"
                          style={{ fontSize: 16, fontWeight: 600, color: "#545454" }}
                        >
                          {start_time?.split("T")[0]}
                        </div>
                      </div>
                      <div className="d-flex align-items-center ps-4">
                        <CiClock2
                          className="sub-assi-txt"
                          style={{ fontSize: 20, color: "#545454" }}
                        />
                        <div
                          className="sub-assi-txt"
                          style={{ fontSize: 16, fontWeight: 600, color: "#545454" }}
                        >
                          {start_time?.split("T")[1].split(".")[0]} -{" "}
                          {end_time?.split("T")[1].split(".")[0]}
                        </div>
                      </div>
                    </div>
                    <div
                      className="sub-assi-txt pt-3 text-start"
                      style={{ fontSize: 18, fontWeight: 500, color: "#545454" }}
                    >
                      Course : {cou_name}
                    </div>
                  </div>
                </div>
                <div className="row px-4 mb-5">
                  <div
                    className="text-start"
                    style={{ fontSize: 18, color: "#545454", fontWeight: 400 }}
                  >
                    Subject : {sub_name}
                  </div>
                </div>
                <div className="row px-4">
                  <div className="col-12">
                    <div className="card px-3">
                      <div className="row mt-3">
                        <div className="col-5 text-start">
                          <div
                            className="sub-name-txt"
                            style={{
                              fontSize: 16,
                              fontWeight: 400,
                              color: "#7E7E7E",
                            }}
                          >
                            Total Marks
                          </div>
                          <div
                            className="text-start"
                            style={{
                              color: "#1E1E1E",
                              fontSize: 20,
                              fontWeight: 600,
                            }}
                          >
                            {tot_marks}
                          </div>
                        </div>
                        {/* <div className="col-4 text-start">
                    <div
                      className="sub-name-txt"
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "#7E7E7E",
                      }}
                    >
                      My Result
                    </div>
                    <div
                      className=""
                      style={{
                        color: "#1E1E1E",
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    >
                      {exam}
                    </div>
                  </div> */}
                      </div>
                      <div
                        className="text-start"
                        style={{ color: "#1E1E1E", fontSize: 20, fontWeight: 600 }}
                      >
                        Instructor’s Note
                      </div>
                      <div
                        class="scroll-div card mx-2 border-0"
                        style={{ fontFamily: "inter", fontSize: 14, fontWeight: 400 }}
                      >
                        {ques_paper !== null ?
                          <a href={ques_paper} target="_blank" rel="noopener noreferrer">
                            {ques_paper}
                          </a>
                          : "Not available"}
                      </div>
                      <div className="row mt-4 mx-3 p-0">
                        <div
                          className="text-start p-0 assi-nav-txt"
                          style={{
                            fontSize: 22,
                            fontWeight: 400,
                            color: "#545454",
                            fontFamily: "inter",
                          }}
                        >
                          My Attachments
                        </div>

                        <div
                          class="scroll-div card mx-2 border-0"
                          style={{ fontFamily: "inter", fontSize: 14, fontWeight: 400 }}
                        >
                          {stu_res !== "" ? (
                            <a href={stu_res} target="_blank" rel="noopener noreferrer">
                              {stu_res}
                            </a>
                          ) : "Not available"}


                        </div>

                      </div>

                      <div className="row mx-3 mt-5 mb-4">
                        <label
                          className="py-4 assi-nav-txt"
                          style={{
                            width: "100%",
                            backgroundColor: "#DDE2EA",
                            borderRadius: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            color: "#95A1B6",
                            fontSize: 20,
                            fontFamily: "inter",
                          }}
                          htmlFor="imageUpload"
                        >
                          <FiUpload style={{ fontSize: 25 }} />
                          &nbsp; Upload File
                        </label>
                        <input
                          id="imageUpload"
                          type="file"
                          accept=".pdf"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />

                        <button
                          type="button"
                          className="btn btn-outline mt-4"
                          style={{
                            width: "100%",
                            border: "1px solid #ED7A2B",
                            color: "#ED7A2B",
                            borderRadius: 5,
                          }}
                          onClick={() => submit()}
                        >
                          Submit Exam
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
      }

    </div>
  );
}
