import {
  get_history_class_error,
  get_history_class_loading,
  get_history_class_success,
  get_selected_class_error,
  get_selected_class_loading,
  get_selected_class_success,
  get_upcoming_class_error,
  get_upcoming_class_loading,
  get_upcoming_class_success,
} from "./class.types";

const initState = {
  isLoading: false,
  isError: false,
  data: {
    historyClass: [],
    upcomingClasses: [],
    classHistory: [],
    selectedClass: {},
  },
};

export const classReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case get_upcoming_class_loading: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case get_upcoming_class_error: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }

    case get_upcoming_class_success: {
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          upcomingClasses: payload,
        },
      };
    }

    case get_history_class_loading: {
      return {
        ...state,
        isLoading: true,
     
      };
    }
    case get_history_class_error: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      
      };
    }

    case get_history_class_success: {
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          classHistory: payload,
          historyClass: payload,
        },
      };
    }

    case get_selected_class_loading: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case get_selected_class_error: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }

    case get_selected_class_success: {
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          selectedClass: payload,
        },
      };
    }

    default:
      return state;
  }
};
